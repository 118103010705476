<template>
  <div>
    <div v-if="item.entity == 'nvr'">
      NVR
      <span
        class="sim-hover primary--text"
        @click="openCameraDialog(item.details.exid)"
      >
        {{ item.details.name }}
      </span>
      device settings.
    </div>
    <div v-if="item.entity == 'routers'">
      {{ item.details.message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openCameraDialog(cameraId) {
      this.$emit("open-camera-dialog", cameraId)
    },
  },
}
</script>
