<template>
  <div>
    <span class="green--text">Camera came online</span>
    <span v-if="cameraId">{{
      getFormattedDatesDiff(item.details.online, item.details.offline)
    }}</span>
    <span
      v-else-if="item.details.camera"
      class="sim-hover primary--text"
      @click="openCameraDialog(item.details.camera.exid)"
    >
      {{ item.details.camera.name }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    cameraId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    openCameraDialog(cameraId) {
      this.$emit("open-camera-dialog", cameraId)
    },
  },
}
</script>
