<template>
  <div>
    <div v-if="item.details.router">
      {{ item.action == "link_sim_with_router" ? "Linked" : "Unlinked" }}
      SIM
      <span
        class="sim-hover primary--text"
        @click="openSmsHistoryDialog(item.details.router.simNumber)"
      >
        {{ item.details.router.simNumber }}
      </span>
      {{ item.action == "link_sim_with_router" ? "with" : "from" }}
      router
      <span
        class="sim-hover primary--text"
        @click="openCameraDialog(item.details.router.serialNumber)"
      >
        {{ item.details.router.serialNumber }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openCameraDialog(cameraId) {
      this.$emit("open-camera-dialog", cameraId)
    },
    openSmsHistoryDialog(item) {
      this.$emit("openSmsHistoryDialog", item)
    },
  },
}
</script>
