<template>
  <div>
    Sent camera
    <span class="sim-hover primary--text" @click="openCameraDialog(getExid)">
      {{ getName }}
    </span>
    share request to {{ getShareeEmail }}.
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getExid() {
      return this.item.details?.exid || this.item.details?.camera?.exid
    },
    getName() {
      return this.item.details?.name || this.item.details?.camera?.name
    },
    getShareeEmail() {
      return this.item.details?.with || this.item.details?.camera?.with
    },
  },
  methods: {
    openCameraDialog(cameraId) {
      this.$emit("open-camera-dialog", cameraId)
    },
  },
}
</script>
