<template>
  <div>
    <span
      v-if="item.details.camera"
      class="sim-hover primary--text"
      @click="openCameraDialog(item.details.camera.exid)"
    >
      {{ item.details.camera.name }}
    </span>
    <span class="red--text">Camera went offline</span>
    {{ getOfflineCause(item) }}
  </div>
</template>

<script>
import ErrorsCauseDescriptions from "@evercam/shared/constants/errorCause"

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      causeDescriptions: ErrorsCauseDescriptions,
    }
  },
  methods: {
    openCameraDialog(cameraId) {
      this.$emit("open-camera-dialog", cameraId)
    },
    getOfflineCause(log) {
      return this.causeDescriptions[log?.details?.camera?.reason] || ""
    },
  },
}
</script>
