<template>
  <div>
    <div v-if="item.details.camera">
      Updated camera
      <span
        class="sim-hover primary--text"
        @click="openCameraDialog(item.details.camera.exid)"
      >
        {{ item.details.camera.name }}
      </span>
      share rights for {{ item.details.camera.with }}.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  methods: {
    openCameraDialog(cameraId) {
      this.$emit("open-camera-dialog", cameraId)
    },
  },
}
</script>
