<template>
  <div>
    Camera status changed from
    {{ getOldStatus }}
    to
    {{ getNewStatus }}
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getOldStatus() {
      return (
        this.item.details?.settings?.old?.status ||
        this.item.details?.camera?.settings?.old?.status
      )
    },
    getNewStatus() {
      return (
        this.item.details?.settings?.new?.status ||
        this.item.details?.camera?.settings?.new?.status
      )
    },
  },
}
</script>
