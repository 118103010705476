<template>
  <div>
    {{ item.details.camera.oldOwner }} transfered camera
    <span
      class="sim-hover primary--text"
      @click="openCameraDialog(item.details.archive.cameraExid)"
    >
      {{ item.details.camera.name }}
    </span>
    to {{ item.details.camera.newOwner }}.
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openCameraDialog(cameraId) {
      this.$emit("open-camera-dialog", cameraId)
    },
  },
}
</script>
