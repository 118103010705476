export default [
  {
    value: "insertedAt",
    text: "Date & Time",
    sortable: true,
    visible: true,
    width: 80,
  },
  {
    value: "who",
    text: "User",
    sortable: true,
    visible: true,
    width: 100,
  },
  {
    value: "entity",
    text: "Entity",
    sortable: true,
    visible: true,
    width: 100,
  },
  {
    value: "ip",
    text: "IP Address",
    visible: true,
    width: 100,
    align: "center",
  },
  {
    value: "event",
    text: "Event",
    sortable: true,
    visible: true,
    width: 300,
  },
]
