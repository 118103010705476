<template>
  <div>
    <span class="sim-hover primary--text">
      {{ item.who }}
    </span>
    logout from
    <span v-if="item.details.fromAdmin">admin</span>
    <span v-else>dashboard</span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
