<template>
  <div style="background-color: #ffff8d">
    Private Note (<span
      class="sim-hover primary--text"
      @click="openCameraDialog(item.details.exid || item.details.camera.exid)"
    >
      {{ item.details.name || item.details.camera.name }} </span
    >):
    {{ getCustomMessage }}
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getCustomMessage() {
      return (
        this.item.details?.customMessage ||
        this.item.details?.camera?.customMessage
      )
    },
  },
  methods: {
    openCameraDialog(cameraId) {
      this.$emit("open-camera-dialog", cameraId)
    },
  },
}
</script>
